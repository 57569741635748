<template>
  <v-row justify="center">
    <v-col>
      <v-alert :v-bind="InfectionRiskCount" class="white--text" :class="this.InfectionRisk.colorClass" :icon="faceMaskPath" prominent text>
        <h3 class="text-h5">
          Risikoeinschätzung
        </h3>
        <div>
          {{ this.InfectionRisk.t }}
        </div>
      </v-alert>

      <h2>Sichere Räume in einer Pandemie</h2>
      <p>Punktesystem zur Risikoeinschätzung</p>
      <small>Weitere Infos findest du <a href="/docs/LUFTHYGIENE_CHECK.pdf" target="_blank">hier</a></small>
      <br><br>
      <v-card>
        <v-card-title>
          Raumvolumen/Person (m³)
        </v-card-title>
        <v-card-subtitle>
          Je größer der Raum, umso geringer ist die Konzentration der Viren im
          Raum.
        </v-card-subtitle>
        <v-col>
          <v-slider
              thumb-label="always"
              step="1"
              min="1"
              :max="5"
              ticks="always"
              tick-size="1"
              :tick-labels=QualityData.RoomVolume.values
              v-model="CurrentRoomVolume"
              label=""
          ></v-slider>
        </v-col>
      </v-card>
      <br>
      <v-card>
        <v-card-title>
          Raumhöhe (m)
        </v-card-title>
        <v-card-subtitle>
          Durch den vom Menschen erzeugten Vertikal-Flow sind hohe Räume besonders
          sicher, denn die abgeatmeten virushaltigen Aerosole bewegen sich nach oben.
        </v-card-subtitle>
        <v-col>
          <v-slider
              thumb-label="always"
              step="1"
              min="1"
              :max="4"
              ticks="always"
              tick-size="1"
              :tick-labels=QualityData.RoomHeight.values
              v-model="CurrentRoomHeight"
              label=""
          ></v-slider>
        </v-col>
      </v-card>
      <br>
      <v-card>
        <v-card-title>
          Maskenträger im Raum (%)
        </v-card-title>
        <v-card-subtitle>
          Mit der Anzahl der Personen steigt die
          Wahrscheinlichkeit, dass sich ein Infizierter im Raum befindet und je mehr Menschen im
          Raum sind umso mehr können sich potentiell infizieren.
        </v-card-subtitle>
        <v-col>
          <v-slider
              thumb-label="always"
              step="1"
              min="1"
              :max="5"
              ticks="always"
              tick-size="1"
              :tick-labels=QualityData.MaskedPersons.values
              v-model="CurrentMaskedPersons"
              label=""
          ></v-slider>
        </v-col>
      </v-card>
      <br>
      <v-card>
        <v-card-title>
          Atmungsaktivität
        </v-card-title>
        <v-card-subtitle>
          Atemfrequenz und Atemtiefe der infizierten und nicht infizierten Personen im Raum.
          Vermehrte Ventilation entsteht z. B. bei körperlicher Arbeit, Sport und Singen.
        </v-card-subtitle>
        <v-card-text class="pt-0">
          <v-slider
              thumb-label="always"
              step="1"
              min="1"
              :max="5"
              ticks="always"
              tick-size="1"
              :tick-labels=QualityData.Breathability.points
              v-model="CurrentBreathability"
              label=""
          ></v-slider>
          <ul>
            <li>(1) => normal</li>
            <li>(2) => sprechen</li>
            <li>(3) => lautes sprechen</li>
            <li>(4) => singen/lärmen</li>
            <li>(5) => körperliche Arbeit/Sport</li>
          </ul>
        </v-card-text>
      </v-card>
      <br>
      <v-card>
        <v-card-title>
          Aufenthaltszeit (min)
        </v-card-title>
        <v-card-subtitle>
          Durch eine infektiöse Person im Raum steigt die Konzentration der Viren in
          der Zimmerluft mit der Zeit und je länger sich nicht infizierten Personen in dem Raum
          aufhalten, desto mehr Viren inhalieren sie.
        </v-card-subtitle>
        <v-col>
          <v-slider
              thumb-label="always"
              step="1"
              min="-4"
              :max="0"
              ticks="always"
              tick-size="1"
              :tick-labels=QualityData.LengthOfStay.values
              v-model="CurrentLengthOfStay"
          ></v-slider>
        </v-col>
      </v-card>
      <br>
      <hr>
      <br>
      <v-alert dense
               outlined
               shaped
               text>
        <span class="text--black">
          Sollte kein CO2-Monitor oder Luftreiniger vorhanden sein, werden automatisch 0 Punkte zur Berechnung benutzt.
        </span>
      </v-alert>
      <v-switch
          v-model="ShowCO2Monitor"
          :label="`CO2 Monitor vorhanden?`"
          v-on:change="this.switchCO2MonitorChanged"
      ></v-switch>
      <v-card v-if="ShowCO2Monitor">
        <v-card-title>
          Lüftungsrate über CO2 - Konzentration (ppm)
        </v-card-title>
        <v-card-subtitle>
          <span>
            Liegen keine CO2- oder Aerosol Konzentrationsmessungen vor, dann sollten jeweils 0 Punkte eingetragen werden
          </span>
        </v-card-subtitle>
        <v-col>
          <v-slider
              thumb-label="always"
              step="1"
              min="-4"
              :max="0"
              ticks="always"
              tick-size="1"
              :tick-labels=QualityData.VentilationRate.values
              v-model="CurrentVentilationRate"
              label=""
          ></v-slider>
        </v-col>
      </v-card>
      <v-switch
          v-model="ShowVentilation"
          :label="`Luftreiniger vorhanden?`"
          v-on:change="this.switchVentilationChanged"
      ></v-switch>

      <div v-if="ShowVentilation">
        <v-card>
          <v-card-title>
            Luftreiniger- Umsatzrate bezogen auf den Rauminhalt (CADR/Rauminhalt)
          </v-card-title>
          <v-card-subtitle>
          <span>
            Liegen keine CO2- oder Aerosol Konzentrationsmessungen vor, dann sollten jeweils 0 Punkte eingetragen werden
          </span>
          </v-card-subtitle>
          <v-col>
            <v-slider
                thumb-label="always"
                step="1"
                min="-4"
                :max="0"
                ticks="always"
                tick-size="1"
                :tick-labels=QualityData.AirCleanerRate.values
                v-model="CurrentAirCleanerRate"
                label=""
            ></v-slider>
          </v-col>
        </v-card>
        <br>
        <v-card>
          <v-card-title>
            PM 2,5 Aerosol- Konzentration (μg/m³)
          </v-card-title>
          <v-col>
            <v-slider
                thumb-label="always"
                step="1"
                min="-4"
                :max="0"
                ticks="always"
                tick-size="1"
                :tick-labels=QualityData.AerosolConcentration.values
                v-model="CurrentAerosolConcentration"
                label=""
            ></v-slider>
          </v-col>
        </v-card>
        <br><br>
      </div>
      <v-alert :v-bind="InfectionRiskCount" :class="this.InfectionRisk.colorClass" class="white--text" :icon="faceMaskPath" prominent text>
        <h3 class="text-h5">
          Risikoeinschätzung
        </h3>
        <div>
          {{ this.InfectionRisk.t }}
        </div>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>

import data from "@/data/aq-data";
import {mdiFaceMask} from '@mdi/js'

export default {
  name: "air-quality-checker",
  data() {
    return {
      faceMaskPath: mdiFaceMask,
      QualityData: data,
      ShowVentilation: false,
      ShowCO2Monitor: false,
      CurrentRoomVolume: 1,
      CurrentRoomHeight: 1,
      CurrentMaskedPersons: 1,
      CurrentBreathability: 1,
      CurrentVentilationRate: 0,
      CurrentAirCleanerRate: 0,
      CurrentAerosolConcentration: 0,
      CurrentLengthOfStay: -4
    }
  },
  methods: {
    switchVentilationChanged: function () {
      this.CurrentAirCleanerRate = 0;
      this.CurrentAerosolConcentration = 0;
    },
    switchCO2MonitorChanged: function () {
      this.CurrentVentilationRate = 0;
    },
  },
  computed: {
    InfectionRiskCount: function () {
      return this.CurrentRoomVolume + this.CurrentRoomHeight + this.CurrentMaskedPersons + this.CurrentBreathability + this.CurrentVentilationRate + this.CurrentAirCleanerRate + this.CurrentAerosolConcentration + this.CurrentLengthOfStay;
    },
    InfectionRisk: function () {
      let t = "";
      let colorClass = "";

      if (this.InfectionRiskCount < 1) { // green
        t = "sehr geringes Risiko";
        colorClass = "green";
      } else if (this.InfectionRiskCount >= 1 && this.InfectionRiskCount < 6) { // lime
        t = "geringes Risiko";
        colorClass = "lime";
      } else if (this.InfectionRiskCount >= 6 && this.InfectionRiskCount < 10) { // yellow
        t = "mittleres Risiko";
        colorClass = "yellow";
      } else if (this.InfectionRiskCount >= 10 && this.InfectionRiskCount < 15) { // orange
        t = "hohes Risiko";
        colorClass = "orange";
      } else if (this.InfectionRiskCount >= 15 && this.InfectionRiskCount <= 19) { // red
        t = "sehr hohes Risiko";
        colorClass = "red";
      }

      return {
        t,
        colorClass
      };
    }
  }
}
</script>

<style scoped>
.green {
  background-color: #1B8344 !important;
}

.lime {
  background-color: #26A853 !important;
}

.yellow {
  background-color: #EFAA3F !important;
}

.orange {
  background-color: #D12532 !important;
}
.red {
  background-color: #780228 !important;
}
</style>
